@import './grid.css';

ion-item[button] {
  pointer-events: auto !important;
}

.cfg-modal {
  background: rgba(255, 255, 255, 0.75);
  left: 0;
  position: absolute;
  top: 40%;
  width: 100%;
  z-index: 2;
}

.cfg-modal .ion-page {
  align-items: center;
  display: flex;
  font-size: 24px;
  justify-content: center;
  text-align: center;
}

.cfg-modal h3 {
  font-size: 36px;
  font-weight: 200;
  margin-bottom: 0;
}

.cfg-modal p {
  font-size: 24px;
  margin-top: 0;
}

.badge {
  height: 200px;
  width: 200px;
}

.badge-color-1 path,
.badge-color-1 polyline {
  fill: #773636;
}

.badge-color-2 path,
.badge-color-2 polyline {
  fill: #80b580;
}

.badge-color-3 path,
.badge-color-3 polyline {
  fill: #737392;
}

.badge-color-4 path,
.badge-color-4 polyline {
  fill: #bfbf7a;
}

.badge-color-5 path,
.badge-color-5 polyline {
  fill: #774d77;
}

.badge-color-6 path,
.badge-color-6 polyline {
  fill: #68bdbd;
}

.group-form {
  --width: 97vw;
}

.quote {
  font-family: var(--font-serif);
  line-height: 30px;

  i {
    font-size: 20px;
  }
}
