/* styles.css */
.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal .modal-wrapper {
  width: auto;
  height: auto;
  max-width: 90%;
  max-height: 90%;
}
