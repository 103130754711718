.cfg-slides .swiper-pagination {
  bottom: 60px;
}

.cfg-slides .swiper-wrapper {
  align-items: center;
  display: flex;
  height: 100vh;
}

.slide-wrapper {
  align-items: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding: 0 20px;
  width: 95vw;
}

.slide-content {
  align-items: center;
  display: flex;
  flex: 1;
}

.slide-footer {
  flex: 0;
  margin-bottom: 40px; /* space for the slider dots */
}

.done-button,
.skip-button {
  --background: transparent;
  color: black;
  text-decoration: underline;
  z-index: 9;
}
